<template>
  <app-form-view
    app="customer_company"
    model="customercompany"
    api-url="customer-company/customer_company/"
    :title="$t('menu.customerCompany')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="secondaryName"
            rules="required"
            :label="$t('fields.taxID')"
            :view="view"
            v-model="formData.tax_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveSecondaryName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_tax_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'customer-company-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_name', 'approve_tax_id']
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'customerCompanyCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'customerCompanyEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'customerCompanyEdit',
          text: value,
          to: {
            name: 'customerCompanyEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {}
}
</script>
